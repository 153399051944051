const symbolOfCurrency = (currency) => {
  return symbols[currency] ? symbols[currency] : ''
}

const symbols = {
  'USD': '$',
  'JPY': '¥',
  'GBP': '£',
  'EUR': '€'
}

export { symbolOfCurrency }