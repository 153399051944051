<template>
	<div class="common-table" :class="`table-${maxDisplayRows}-rows ${overflowClass} ${hasSummaryRowClass} ${isFilterShownClass} table-header-height-${tableHeaderOffsetHeight}-px w-100`">
		<div class="table-outer-1 w-100" ref="table-outer-1">
			<div class="table-outer-2 w-100"
				ref="table-outer-2" 
				@scroll="scrollContentHandler"
			>
					<b-table-simple
						:small="small"
						:hover="hover"
						:no-border-collapse="noBorderCollapse"
						:bordered="bordered"
						:striped="striped"
						class="w-100"
					>
						<b-thead :style="{
							'overflow-y': computedItems.length + 1 < maxDisplayRows ? 'auto !important' : 'scroll !important'
						}"
							ref="header"
						>
							<b-tr ref="header-1st-row">
								<slot name="header">
									<b-th
										v-for="(field, index) in computedFields"
										:key="index"
										:class="{
											...field.thClass,
											'cursor-pointer': field.sortField,
											'justify-content-center': !isFilterZeroShown,
											'justify-content-between': isFilterZeroShown
										}"
										:style="{
											'padding': '12px 0.5rem',	// 100px is to large for some column
											'font-weight': 'bold',
											'padding-bottom': isFilterZeroShown ? '30px' : '12px',
											...field.thStyle
										}"
										@click="sortField(field)"
									>
											<slot :name="`head(${field.key})`" :field="field" :value="field.label">
													<div>
														{{ field.label }}
														<font-awesome-icon v-if="field_sort == field.sortField" :icon="['fal', `chevron-${isDesc ? 'down' : 'up'}`]" class="font-small-3" />
													</div>
													<div class="checkbox-hide-zero-values" v-if="isFilterZeroShown && field.canFilterOutZeroValues" @click.stop>	
														<b-form-checkbox 
															:checked="filterOutZeroValues[field.key]"
															@change="handleFilterOutZeroValues($event, field.key)"
															size="sm"
															v-b-tooltip.hover.right title="hide 0 values"
														></b-form-checkbox>
													</div>
											</slot>
									</b-th>
								</slot>
							</b-tr>
							<slot name="filter">
								<TableFilter
									:isShown="isFilterShown"
									:fields="computedFields"
									@change="$emit('filterChange', $event)"
									@clear="$emit('filterClear', $event)"
									ref="filter"
								>
									<template 
										v-for="field in computedFields"
										:slot="`filter(${field.key})`"
									>
										<slot :name="`filter(${field.key})`" :field="field" :filter="field.filter">
											
										</slot>
									</template>
								</TableFilter>
							</slot>
							<slot name="summary-row" ref="summary-row"></slot>
						</b-thead>
						<!-- <div class="body-wrapper"
							:style="{
								'overflow-y': items.length + 1 < maxDisplayRows ? 'auto !important' : 'scroll !important'
							}"
						> -->
						<b-td v-if="computedItems.length == 0 || isLoading" class="w-100 py-5 text-center" style="border: none !important" :colspan="fields.length">
							<b-spinner variant="primary" v-if="isLoading"></b-spinner>
							<div v-else>
								There is no data to show
							</div>
						</b-td>
						<b-tbody 
              v-else
						>
							<b-tr
								v-for="item, index in computedItems"
								:key="index"
								@click="onClickRow(item, index)"
							>
								<b-td 
									v-for="field, jj in computedFields"
									:key="field.key"
									:class="`${field.tdClass ? field.tdClass : ''}`"
									:style="{
										...field.tdStyle, 
										'background-color': `${hasHighlight && highlight[item.name] ? '#192b3e': ''}`,
										...getCustomCellStyle(item, field),
									}"
									:ref="`cell-${index}-${jj}`"
								>
									<slot :name="`cell(${field.key})`" :item="item" :index="index" :field="field" :value="item[field.key]">
										<span :class="item[field.key] == 'N/A' ? 'text-secondary' : field.colorRedGreen ? (item[field.key].toString().includes('-') ? 'text-danger' : 'text-success') : ''">
											<span v-if="field.displayAbsolute">
												{{ item[field.key] instanceof String ? item[field.key].replace('-', '') : item[field.key]}}	
											</span>
											<span v-else-if="typeof item[field.key] === 'number'">
												{{ item[field.key] }}	
											</span>
											<span v-else>
												{{ item[field.key] }}	
												<!-- {{ $refs[`row-${index}`] }} -->
											</span>
										</span>
									</slot>
								</b-td>
							</b-tr>
						</b-tbody>
					</b-table-simple>
			</div>
		</div>
		<b-tfoot v-if="items && items.length > 0 && hasFooter" class="footerClass">
			<b-th :class="field.thClass" :style="field.thStyle" v-for="(field, index) in computedFields" :key="index" >
				<slot :name="`footer(${field.key})`" :field="field">
					<div v-if="field.footer">
						{{field.footer(items)}}
					</div>
				</slot>
			</b-th>
		</b-tfoot>
		<!-- Use an anchor -->
		<div ref="anchor" style="left: 0; position: absolute; top: 0"></div>

		<!-- Custom scrollbar -->
		<div ref="scrollbar-anchor" class="scrollbar-anchor"
			v-if="isMounted  && !isLoading && computedItems.length > maxDisplayRows"
			:style="{
				top: 0,
				right: '0px',
				height: `${tableOuterHeight}px`
			}"
		>
		
			<div ref="track" class="track"
				:style="{
					height: trackHeight + 'px',
					bottom: 0
				}"
			>
			<div ref="thumb" class="thumb"
				:style="{
					height: thumbHeight + 'px',
					top: thumbTopPosition + 'px'
				}"
				@mousedown="mouseDownThumbHandler"
			></div>
			</div>
		</div>
	</div>
</template>

<script>
import { formatMoney } from '@/helper'
import variables from '@/assets/scss/variables/_variables-components.scss'
import vSelect from 'vue-select'
import TableFilter from '@/components/tables/TableFilter.vue'

export default {
  components: {
    vSelect,
    TableFilter
  },
	props: {
		field_sort:{
			type: String,
			default: ''
		},
		isFilterZeroShown: {
			type: Boolean,
			default: false
		},
		isDesc: {
			type: Boolean,
			default: true
		},
		small: {
			type: Boolean,
			default: true
		},
		hover: {
			type: Boolean,
			default: true
		},
		noBorderCollapse: {
			type: Boolean,
			default: true
		},
		bordered: {
			type: Boolean,
			default: true
		},
		striped: {
			type: Boolean,
			default: true
		},
		maxDisplayRows: {
			type: Number,
			default: 19
		},
		hasSummaryRow: {
			type: Boolean,
			default: true
		},
	
		hasFooter: {
			type: Boolean,
			default: false
		},
    isLoading: {
      type: Boolean,
      default: false,
    },
		hasHighlight: {
			type: Boolean,
      default: true,
		},
    isFilterShown: {
      type: Boolean,
      default: false
    },
		fields: {
      type: Array,
      default() {
        return []
      },
    },
		items: {
      type: Array,
      default() {
        return []
      }
    }
	},
	data() {
		return {
			isMounted: false,
			tableRowHeight: parseInt(variables.tableRowHeight),
			tableFilterHeight: parseInt(variables.tableFilterHeight),
			pos: { top: 0, y: 0 },
			isMouseDown: false,
			thumbTopPosition: 0,
			filterOutZeroValues: {},
			highlight: {},
			tableHeaderOffsetHeight: 0 // header doesn't have fixed height so it won't have 'style.height' property but a style property called 'offset-height'
		}
	},
	methods: {
		formatMoney,
		handleHeaderHeight(defer = false) {
			if (defer) {
				// defer to next tick because the style (offset-height) doesn't get updated immediately after toggling checkbox visibility
				this.$nextTick()
					.then(() => {
						this.handleHeaderHeight()
					})
			}
			let header = this.$refs['header']
			let firstRow = this.$refs['header-1st-row'].$el
			let firstRowHeight = firstRow.offsetHeight

			this.tableHeaderOffsetHeight = firstRowHeight
			// let filterRow = this.$refs['header-1st-row'].$el
			let filterRow = header.$children[1].$el

			if (this.hasSummaryRow) {
				let summaryRow = header.$children[2].$el
				if (this.isFilterShown) {
					filterRow.style.top = firstRowHeight + 'px'
					summaryRow.style.top = this.tableFilterHeight + firstRowHeight + 'px'
				} else summaryRow.style.top = firstRowHeight + 'px'
			} else if (this.isFilterShown) {
				filterRow.style.top = firstRowHeight + 'px'
			}
			// console.log(this.$refs['table-outer-1'])
			this.$refs['table-outer-1'].style.maxHeight = this.tableOuterHeight + 'px'
			this.$refs['table-outer-2'].style.maxHeight = this.tableOuterHeight + 'px'
			// console.log(this.maxDisplayRows, this.tableOuterHeight)
		},
		getCustomCellStyle(item, field) {
			if (field.customTdStyle) {
				return field.customTdStyle(item)
			}
			return {}
		},
		sortField(field) {
			if (field.sortField) this.$emit('onHeaderClick', field.sortField)
		},
		clearFilter() {
			this.$refs.filter.clearFilter()
		},
		initFilter(filterValues) {
			this.$refs.filter.initFilter(filterValues)
		},
		mouseDownThumbHandler(e) {
			this.isMouseDown = true
			this.pos = {
				// The current scroll 
				top: this.$refs['table-outer-2'].scrollTop,
				// Get the current mouse position
				y: e.clientY,
			}
			document.addEventListener('mousemove', this.mouseMoveHandler)
			document.addEventListener('mouseup', () => {
				this.isMouseDown = false
			})
		},
		mouseMoveHandler(e) {
			if (!this.isMouseDown) return
			// How far the mouse has been moved
			const dy = e.clientY - this.pos.y;
			this.$refs['table-outer-2'].scrollTop = this.pos.top + dy / this.scrollRatio;
		},

		scrollContentHandler(e) {
		
			this.$nextTick(() => {
				this.thumbTopPosition = (this.trackHeight - this.thumbHeight) * (e.target.scrollTop / (e.target.scrollHeight - this.tableOuterHeight))
			})
		},

		onClickRow(item, index) {
			if (this.hasHighlight)
				this.$set(this.highlight, item.name, !this.highlight[item.name])
			this.$emit('row-clicked', { item, index })
		},

		initFilterZero(filterValues) {
			Object.keys(filterValues).forEach(key => {
				if (key != 'show') this.handleFilterOutZeroValues(filterValues[key], key)
			})
		},
		handleFilterOutZeroValues(value, key) {
			this.$set(this.filterOutZeroValues, key, value)
			this.$emit('filterOutZeroValues', this.filterOutZeroValues)
		},
		isZero(item, field) {
			if (parseFloat(item[field]) === 0 || isNaN(parseFloat(item[field]))) return true
			if (field === 'Risk') return item.Risk === 1 || !('Risk' in item)
		},
		chooseHeaderHeight() {
			
		}
	},
	computed: {
    computedFields() {
      let tmp = [...this.fields]  // shallow copy: avoid multiple push and retain functions (if any) in fields
      if (this.isFilterShown) {   // if filter is showing then add one more column for Clear button
        // tmp.push({
        //   key: "clear_filter",
        //   label: "",
        //   filter: {
        //     type: "clear"
        //   }          
        // })
      }
      return tmp
    },
		computedItems() {
			return this.items
		},
		tableHeaderHeight() {
			let tableHeaderHeight = this.tableHeaderOffsetHeight
			if (this.isFilterShown) tableHeaderHeight += this.tableFilterHeight
			if (this.hasSummaryRow) tableHeaderHeight += this.tableRowHeight
			return tableHeaderHeight
		},
		scrollRatio() {
			return this.maxDisplayRows / this.computedItems.length
		},
		trackHeight() {
			return this.maxDisplayRows * this.tableRowHeight 
		},
		thumbHeight() {
			return this.scrollRatio * this.trackHeight
		},
		overflowClass() {
			if (this.computedItems.length > this.maxDisplayRows) return 'table-overflow-y'
			return ''
		},
		hasSummaryRowClass() {
			if (this.hasSummaryRow) return 'table-has-summary-row'
			return ''
		},
		isFilterShownClass() {
			if (this.isFilterShown) return 'table-filter-shown'
			return ''
		},
		tableOuterHeight() {
			return this.maxDisplayRows * this.tableRowHeight + this.tableHeaderHeight 
		}
	},
	watch: {
		computedFields() {
			this.handleHeaderHeight(true)
		},
		isFilterShown() {
			this.handleHeaderHeight()
		},
		isFilterZeroShown() {
			this.handleHeaderHeight(true)
		},
		maxDisplayRows() {
			this.handleHeaderHeight()
		},
		items() {
			this.handleHeaderHeight(true)
		}
	},
	mounted() {
		this.isMounted = true
		this.handleHeaderHeight()
		// this.thumbTopPosition = this.tableHeaderHeight
		// this.pos.top = this.tableHeaderHeight
	},
	destroy() {
		this.isMounted = false
	}
}
</script>