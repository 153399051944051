import XLSX from "xlsx";
import moment from "moment";

export * from "./tableGrouping";
export { symbolOfCurrency } from "./currency";
export { removeFilterInLocalStorage, setFilterInLocalStorage, getFilterInLocalStorage } from "./tableFilter";

export const formatMoney = (amount, decimalCount = 2, decimal = ".", thousands = ",") => {
    try {
        if (typeof amount === "string") amount = parseFloat(amount);
        if (amount == null || isNaN(amount)) return "N/A";
        decimalCount = Math.abs(decimalCount);
        decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

        const negativeSign = amount < 0 ? "-" : "";

        let i = parseInt((amount = Math.abs(Number(amount) || 0).toFixed(decimalCount))).toString();
        let j = i.length > 3 ? i.length % 3 : 0;

        return (
            negativeSign +
            (j ? i.substr(0, j) + thousands : "") +
            i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) +
            (decimalCount
                ? decimal +
                  Math.abs(amount - i)
                      .toFixed(decimalCount)
                      .slice(2)
                : "")
        );
    } catch (e) {
        console.error(e);
        return "N/A";
    }
};

export const formatNumber = (amount, decimalCount) => {
    return isNaN(amount) ? "NULL" : parseFloat(amount?.toFixed?.(decimalCount));
};

export const exportFile = (data /* array of arrays */, exportFormat, fileName, serverTimezoneOffset = 120) => {
    // console.log("Export data:", data)
    let worksheet = XLSX.utils.aoa_to_sheet(data);
    let workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "SheetJS");
    let workbook_export = XLSX.write(workbook, { bookType: exportFormat, type: "binary" });

    let buf = new ArrayBuffer(workbook_export.length);
    let view = new Uint8Array(buf);
    for (let i = 0; i < workbook_export.length; i++) view[i] = workbook_export.charCodeAt(i) & 0xff;

    let blob = new Blob([buf], { type: "application/octet-stream;charset=utf-8;" });
    let url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.setAttribute("href", url);
    let currentDatetime = moment().format("YYYY-MM-DD HH:mm:ss");
    link.setAttribute("download", `${fileName} ${currentDatetime}.${exportFormat}`);
    link.click();
};

export const timeDiff = (dateA, dateB, unit = "time") => {
    let diff = Math.abs(new Date(dateB) - new Date(dateA));
    switch (unit) {
        case ("time", "ms"):
            return diff;
        case "s":
            return Math.floor(diff / 1000);
        case "m":
            return Math.floor(diff / (1000 * 60));
        case "H":
            return Math.floor(diff / (1000 * 60 * 60));
        case "D":
            return Math.floor(diff / (1000 * 60 * 60 * 24));
    }
};

export function getWebSocketURL() {
    // const token = localStorage.getItem("accessToken");
    // console.log("getWebSocketURL with token: ", token);
    // if (token) {
    //     return `wss://${process.env.VUE_APP_API_URL.split("//")[1]}cable?token=${token.split(" ")[1]}`;
    // }
}
