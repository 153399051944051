
const removeFilterInLocalStorage = (tableName) => {
  if (localStorage.getItem(tableName + 'Filter')) {
    localStorage.removeItem(tableName + 'Filter')
    return true
  }
  return false
}
const setFilterInLocalStorage = (tableName, filterValues) => {
  localStorage.setItem(tableName + 'Filter', JSON.stringify(filterValues))
}

const getFilterInLocalStorage = (tableName) => {
  if (!localStorage.getItem(tableName + 'Filter')) return false
  return JSON.parse(localStorage.getItem(tableName + 'Filter') ?? '{}')
}

export {
  removeFilterInLocalStorage,
  setFilterInLocalStorage,
  getFilterInLocalStorage
}