<template>
  <b-tr class="table-filter" :class="{ 'table-filter-shown': isShown }">
    <b-th
      v-for="field in fields"
      :key="field.key"
      :class="field.thClass"
      :style="field.thStyle"
      :colspan="field.colspan"
    >
      <div class="cell-inner-wrapper">
      <slot :name="`filter(${field.key})`" :field="field" :filter="field.filter">
        <div :key="field.key" v-if="!field.filter">
          
        </div>
        <b-form-input 
          :key="field.key" 
          v-else-if="field.filter.type == 'input'"
          v-model="filterValues[field.key]"
          @input="$emit('change', filterValues)"
          size="sm"
          class="filter-input"
        />
        <b-form-select 
          :key="field.key" 
          v-else-if="field.filter.type == 'select'"
          v-model="filterValues[field.key]"
          :options="field.filter.options"
          @input="$emit('change', filterValues)"
          class="filter-select"
          size="sm"
        />
        <!-- <v-select 
          :key="field.key" 
          v-else-if="field.filter.type == 'select'"
          v-model="filterValues[field.key]"
          :options="field.filter.options"
          label="text"
          :reduce="option => option.value"
          @input="$emit('change', filterValues)"
          class="filter-select"
          size="sm"
        /> -->
        <!-- <div v-else-if="field.filter.type == 'clear'" class="text-danger ql-underline px-1 cursor-pointer" @click="clearFilter">
          Clear
        </div> -->
      </slot>
      </div> 
    </b-th>
  </b-tr>
</template>

<script>
import vSelect from 'vue-select'
export default {
  components: {
    vSelect
  },
  props: ["fields", "isShown"],

  data() {
    return {
      filterValues: {}
    }
  },

  computed: {
    defaultFilter() {
      return this.fields.reduce((obj, field) => {
        if (field.filter) {
          obj[field.key] = field.filter.default ?? ""
        }
        return obj
      }, {})
    }
  },

  methods: {
    initFilter(filterValues) {
      this.filterValues = filterValues
    },
    clearFilter() {
      // console.log("clear filter")
      Object.keys(this.defaultFilter).forEach(key => {
        this.$set(this.filterValues, key, JSON.parse(JSON.stringify(this.defaultFilter[key])))
      })
      this.$emit('clear', this.filterValues)
    }
  },

  mounted() {
    // console.log(this.defaultFilter)
    Object.keys(this.defaultFilter).forEach(key => {
      this.$set(this.filterValues, key, JSON.parse(JSON.stringify(this.defaultFilter[key])))
    })
  }
}
</script>
<style scoped lang="scss">
@import '@/assets/scss/variables/_variables-components.scss';
// tr {
//   th {
//     transform: translate(0, -$table-filter-height, -1);
//   }
//   &.table-filter-shown {
//     th {
//       transform: translateY(0);
//       z-index: -1;
//     }
//   }
// }
.table-filter {
  height: 0 !important;
  overflow: hidden;
  th {
    padding-top: 0;
    padding-bottom: 0;
    .cell-inner-wrapper {
      max-height: 0;
      height: 0;
      overflow: hidden;
      display: block;
    }
  }
  &.table-filter-shown {
    .cell-inner-wrapper {
      height: fit-content;
      max-height: fit-content;
      overflow: unset;
    }
    th {
      height: $table-filter-height;
      max-height: $table-filter-height;
    }
  }
  ::v-deep .form-control, ::v-deep .custom-select {
    height: 25px !important;
  }
  ::v-deep .custom-select {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  // .filter-select {
  //   max-width: 150px !important;
  //   width: 150px !important;
  // }
}
</style>