/*
    These 3 functions 
*/

export const isFilteredByRegion = (activeLevels, groupLevelsLength) => {
  let check = activeLevels.some(level => level <= groupLevelsLength)
  return check
}

export const getClientEmail = (client) => {
  let arr = client.name.split(' ')
  let email = arr.pop()
  if (email.includes('@')) return email
  return false
}
export const mergeTwoClients = (clientA, clientB, fields) => {
  let result = clientA
  Object.keys(clientA).forEach(key => {
    if (fields.map(field => field.key).includes(key) && !isNaN(clientA[key])) {
      result[key] += clientB[key]
    }
  })
  // console.log('merge', result)
  return result
}

export const getPathWithoutGroup = (fullpath, groupLevelsLength) => {
  let path = fullpath.split('/')
  return path.slice(groupLevelsLength).join("/")
}
